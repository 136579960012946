<template>
  <div class="recruiting">
     <TablePreload v-if="state.preload" :row="10"/>
    <div v-if="!state.preload">
      <div class="recruiting__list">
        <div class="recruiting__list__pagination">
          {{ state.totalClientRecord }} 件表示 / {{ state.total_count }} 件中
        </div>
        <div class="table">
          <table>
            <thead>
              <tr>
                <th>サムネイル</th>
                <th>
                  <a
                    :class="{
                        active:
                            state.fieldSortName == 'title' &&
                            state.fieldSortType == 'asc',
                    }"
                    @click="sortTable('title')"
                    class="column_name"
                  >プロジェクト名</a>
                </th>
                <th>
                  <a
                      :class="{
                        active:
                            state.fieldSortName == 'category_name' &&
                            state.fieldSortType == 'asc',
                    }"
                      @click="sortTable('category_name')">相談パターン</a>
                </th>
                <th>
                  <a
                      :class="{
                        active:
                            state.fieldSortName == 'data_types' &&
                            state.fieldSortType == 'asc',
                    }"
                      @click="sortTable('data_types')">データタイプ</a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="project in state.listProjects" v-bind:key="project.project_id">
                <td class="column_image">
                  <img @error="replaceByDefault" :src="project.image_file_name || noPhoto" :alt="project.title" />
                </td>
                <td class="column_name">
                  <router-link :to="RoutePath.PROJECT_DETAIL + project.project_id">{{ project.title }}</router-link>
                </td>
                <td class="column_category">{{ project.category_name }}</td>
                <td class="column_data_type">
                  <tamplate v-if="Object.keys(project.data_types).length">
                    <template v-for="(data_type, index) in project.data_types" :key="data_type.id">
                      {{ data_type.name }}<template v-if="index < Object.keys(project.data_types).length - 1">、</template>
                    </template>
                  </tamplate>
                  <template v-else>-</template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="btn-load-more">
        <ButtonLoadMore
          v-if="state.totalClientRecord < state.total_count"
          :loading="state.isLoading"
          @click="loadMore()"
        />
      </div>
    </div>
    <span
      v-if="(!state.listProjects || (state.listProjects.length == 0)) && !state.preload"
      class="no-data"
    >募集中のプロジェクトがありません</span>
  </div>
</template>
<script lang="ts" src="@/presentation/views/organization/my-profile/recruiting/Recruiting.ts" />
<style lang="scss" src="@/presentation/views/organization/my-profile/recruiting/Recruiting.scss" scoped></style>
